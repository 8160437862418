$color_1: #555555;
$color_2: #999999;
$color_3: #000000;
$color_4: #ffffff;
$color_5: gray;
$color_6: #fff;
$background_color_1: #f1f1f1;
$background_color_2: transparent;
$background_color_3: #000000;
$background_color_4: #e7e7e7;
$background_color_5: #eee;
$background_color_6: #ccc;
$border_color_1: #ffffff;
$border_color_2: #000000;

#privacy-banner {
	background-color: $background_color_1;
	font-weight: 400;
	a {
		color: $color_1;
		transition: .25s ease-in-out;
		&:hover {
			text-decoration: none;
		}
	}
	.banner-text {
		a {
			&:hover {
				color: $color_2;
				text-decoration: none;
			}
		}
		padding-right: 0;
	}
	.banner-settings-button {
		float: right;
		color: $color_3;
		background-color: $background_color_2;
		border: 2px solid #000000;
		padding: 10px 20px;
		border-radius: 3px;
		margin-left: 10px;
		margin-top: 10px;
	}
	.banner-accept-button {
		float: right;
		color: $color_4;
		background-color: $background_color_3;
		border: 2px solid #000000;
		padding: 10px 20px;
		border-radius: 3px;
		margin-top: 10px;
		&:hover {
			color: $color_4;
		}
	}
	.close-banner {
		float: right;
		cursor: pointer;
	}
	.toggle-check {
		float: right;
		&::before {
			width: 30px !important;
			height: 30px !important;
		}
		&::after {
			width: 30px !important;
			height: 30px !important;
		}
	}
	.banner-buttons {
		padding-left: 0;
	}
}

#privacysettings {
	.alert-info {
		color: $color_1;
		background-color: $background_color_4;
		border-color: $border_color_1;
	}
	.modal-title {
		display: inline-block;
	}
	.category {
		width: 100%;
		padding-bottom: 20px;
		h5 {
			width: 100%;
			color: $color_5;
			display: block;
			position: relative;
			margin-bottom: 12px;
			font-size: 1.375rem;
			font-weight: normal;
		}
	}
	.statistics-item {
		padding-top: 10px;
	}
	button.js-modal-accept {
		color: $color_6;
		background: #222;
		&:hover {
			background-color: $background_color_3;
			border-color: $border_color_2;
		}
	}
	label {
		margin-left: 20px;
	}
	.customcheck {
		display: contents;
		position: relative;
		padding-left: 35px;
		margin-bottom: 12px;
		cursor: pointer;
		font-size: 1.375rem;
		font-weight: normal;
		user-select: none;
		transition: all 0.4s ease 0s;
		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			&:checked {
				&~.checkmark {
					background-color: $background_color_3;
					border-radius: 5px;
					&:after {
						display: block;
					}
				}
			}
		}
		&:hover {
			input {
				&~.checkmark {
					background-color: $background_color_6;
				}
			}
		}
		.checkmark {
			&:after {
				content: "";
				position: absolute;
				display: none;
				left: 38%;
				top: 15%;
				width: 0.4375rem;
				height: 0.875rem;
				border: solid white;
				border-width: 0 0.1875rem 0.1875rem 0;
				transform: rotate(45deg);
			}
		}
	}
	.checkmark {
		position: absolute;
		height: 1.5625rem;
		width: 1.5625rem;
		background-color: $background_color_5;
		border-radius: 5px;
		margin-left: 20px;
		transition: all 0.4s ease 0s;
	}
}
.no-items {
	padding-top: 10px;
}
@media (max-width: 1199px) {
	#privacy-banner {
		.banner-container {
			font-size: 0.8125rem;
		}
		.banner-settings-button {
			padding: 8px 16px;
		}
		.banner-accept-button {
			padding: 8px 16px;
		}
	}
}
@media (max-width: 991px) {
	#privacy-banner {
		.banner-settings-button {
			padding: 8px 16px;
		}
		.banner-accept-button {
			padding: 8px 16px;
		}
		.banner-text {
			display: block;
			width: 100%;
		}
		.text-break {
			display: none;
		}
	}
}
@media (max-width: 767px) {
	#privacy-banner {
		.banner-text {
			font-size: 0.75rem;
			line-height: 1.4;
			padding-bottom: 20px;
			padding-right: 15px;
		}
		.banner-settings-button {
			float: left;
			margin-right: 10px;
		}
		.banner-accept-button {
			float: left;
			margin-left: 10px;
		}
	}
}
